<template>
    <div
        class="bg-white dark:bg-neutral-800  rounded-xl flex flex-col gap-4 dt:flex-row items-center justify-between p-3 pl-[18px]">
        <h3 class="dt:text-left text-center text-black dark:text-white text-[17px]">{{ $t('Embedded.listen-to-title-live', { title }) }}</h3>
        <button @click="handlePlay" type="button"
            class="text-white dark:text-black rounded-xl py-[14px] px-[18px] text-xs font-medium flex items-center gap-2 whitespace-nowrap"
            :style="{ backgroundColor: `#${primaryColor || '1C1C27'}`  }">
            <Icon name="play-fill" />
            <p>
                {{ $t('Embedded.listen-to-live') }}
            </p>
        </button>
    </div>
</template>

<script setup lang="ts">

const props = defineProps<{
    title: string | undefined
    radioId: number | undefined
}>()

const { $player } = useNuxtApp()
const { primaryColor } = useEmbedded()

const handlePlay = () => {
    if (props.radioId) $player.loadRadio(props.radioId)
}

</script>